import React from 'react';
import styled from 'styled-components';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';
import { Link } from 'gatsby';
import adornoimg from '@images/convocatorias/graduation-svgrepo-com.svg'

const IndexPage = () => {


  return (
    <TemplatePage1
      title={"CONVOCATORIAS QuEST LAC"}
      tc={"white"}
      adorno={<Adorno src={adornoimg} alt={adornoimg}/>}
      description={"CONVOCATORIAS QuEST LAC"}
      bg={"var(--tema4)"}
      hero={true}
      content={(
        <Wrapper>
            
            <TextZone>
                <p>Lo sentimos, por el momento, no contamos con convocatorias disponibles.  Sin embargo, nos gustaría conocer más de ti. </p>
                <Button to={"/contacto"}>                    Contáctanos
                </Button>
            </TextZone>
            
        </Wrapper>
      )}
    />
  )
};

export default IndexPage


const Wrapper = styled.div`
position: relative;
max-width: 100%;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
justify-content: center;
align-items: flex-end;
`;

const Adorno = styled.img`
display: none;
height:600px;
right:550px;
@media only screen and (min-width:768px){
    display: block;
}
@media only screen and (min-width:1200px){
  left:0;
  right: auto;
}
position: absolute;
bottom:0;

overflow: hidden;
`

const TextZone = styled.div`
display: flex;
flex-wrap: nowrap;
flex-direction: column;
justify-content: center;
align-items: flex-end;
text-align: center;
color: white;
font-size: 25px;
p{
    margin: 1rem 1.5rem;    
}
@media only screen and (min-width:768px){
max-width: 400px;
text-align: right;
}
width:100%;
`

const Button = styled(Link)`
color: white;
font-size:20px;
background-color: var(--color2);
padding: 10px 15px;
margin: 1rem;
`
